export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;
export const STANDARD_SERVING_SIZE = 2;
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1920;
export const LAPTOP_BREAKPOINT = 1441;
export const SMALL_LAPTOP_BREAKPOINT = 1280;

export const colors = {
  facebookBlue: "#3B5998",
  tan: "#C9A584",
  lightBlue: "#B1CCD1",
  biege: "#E4D2BC",
  bizarre: "#EFE4DA",
  lightBiege: "#F7F2ED",
  darkGreen: "#314A37",
  lightAsGreen: "#E9EDEB",
  lightAsBlue: "#B1CCD1",
  avocado: "#B2BA48",
  white: "#fff",
  black: "#000",
  lightBlack: "#444444",
  errorRed: "#FF9494",
  transparent: "transparent",
  lightGreen: "#D8E5E8",
};

export const colorsRGB = {
  lightBlue: (opacity?: number) => `rgba(177, 204, 209, ${opacity ?? "0.5"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  lightBlack: (opacity?: number) => `rgba(68, 68, 68, ${opacity ?? "0.4"})`,
  darkGreen: (opacity?: number) => `rgba(49, 74, 55, ${opacity ?? "0.2"})`,
  tan: (opacity?: number) => `rgba(201,165,132, ${opacity ?? "0.2"})`,
  avocado: (opacity?: number) => `rgba(178, 186, 72, ${opacity ?? "0.2"})`,
};

export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;

export const themeStyle = {
  base: {
    bg: `${colors.darkGreen}`,
    fg: `${colors.white}`,
    border: `${colors.darkGreen}`,
    hover: `${colors.avocado}`,
    hoverText: `${colors.white}`,
  },
  inverse: {
    bg: `${colors.transparent}`,
    fg: `${colors.darkGreen}`,
    border: `${colors.darkGreen}`,
    hover: `${colors.darkGreen}`,
    hoverText: `${colors.white}`,
  },
  inversewhite: {
    bg: `${colors.transparent}`,
    fg: `${colors.white}`,
    border: `${colors.white}`,
    hover: `${colors.darkGreen}`,
    hoverText: `${colors.darkGreen}`,
  },
  unstyled: {
    bg: `${colors.transparent}`,
    fg: `${colors.darkGreen}`,
    border: `${colors.transparent}`,
    hover: `${colors.darkGreen}`,
    hoverText: `${colors.white}`,
  },
  fileButton: {
    bg: `${colors.lightBlue}`,
    fg: `${colors.darkGreen}`,
    border: `${colors.lightBlue}`,
    hover: `${colors.avocado}`,
    hoverText: `${colors.white}`,
  },
};

export const assets = {
  favouriteIcon: require("@assets/icons/favourite.svg").default,
  favouritedIcon: require("@assets/icons/favourited.svg").default,
  quickView: require("@assets/icons/quick-view.svg").default,
  leftArrowIcon: require("@assets/icons/left-arrow.png").default,
  rightArrowIcon: require("@assets/icons/right-arrow.png").default,
  hamburgerIcon: require("@assets/icons/hamburgerMenu.svg").default,
  headerAccount: require("@assets/icons/account.svg").default,
  headerSearch: require("@assets/icons/search.svg").default,
  searchIcon: require("@assets/icons/search-grey.svg").default,
  account: require("@assets/icons/account.svg").default,
  upArrow: require("@assets/icons/up-arrow.svg").default,
  downArrow: require("@assets/icons/down-arrow.svg").default,
  clock: require("@assets/icons/clock.svg").default,
  close: require("@assets/icons/close.svg").default,
  closeDark: require("@assets/icons/close-dark.svg").default,
  facebook: require("@assets/icons/facebook.svg").default,
  google: require("@assets/icons/google.svg").default,
  facebookLogo: require("@assets/icons/facebook-logo.svg").default,
  instagramLogo: require("@assets/icons/instagram-logo.svg").default,
  pinterestLogo: require("@assets/icons/pinterest-logo.svg").default,
  twitterLogo: require("@assets/icons/twitter-logo.svg").default,
  youtubeLogo: require("@assets/icons/youtube-logo.svg").default,
  linkedinLogo: require("@assets/icons/linkedin-logo.svg").default,
  heart: require("@assets/icons/heart.svg").default,
  forkKnife: require("@assets/icons/fork-knife.svg").default,
  right: require("@assets/icons/right.svg").default,
  defaultRecipe: require("@assets/default_recipe.png").default,
  defaultUser: require("@assets/default_user.png").default,
  tag: require("@assets/icons/tag.svg").default,
  play: require("@assets/icons/play.svg").default,
  add: require("@assets/icons/add.svg").default,
  tick: require("@assets/icons/tick.svg").default,
  plus: require("@assets/icons/plus.svg").default,
  addLink: require("@assets/icons/add-link.svg").default,
  addIcon: require("@assets/icons/add-icon.svg").default,
  addSaved: require("@assets/icons/add-saved.svg").default,
  moreCircle: require("@assets/icons/more-circle.svg").default,
  more: require("@assets/icons/more.svg").default,
  calendar: require("@assets/icons/calendar.svg").default,
  closeWhite: require("@assets/icons/close-white.svg").default,
  facebookLight: require("@assets/icons/facebookLight.svg").default,
  twitterLight: require("@assets/icons/twitterLight.svg").default,
  pinterestLight: require("@assets/icons/pinterestLight.svg").default,
  bookmark: require("@assets/icons/bookmark.svg").default,
  logo: require("@assets/icons/logo.svg").default,
  badgeWhite: require("@assets/badgeWhite.svg").default,
  badgeRed: require("@assets/badgeRed.svg").default,
  cookIcon: require("@assets/icons/cookIcon.svg").default,
  loggedInLogo: require("@assets/myrecipes.svg").default,
  plate: require("@assets/plate.png").default,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  competition: "/competition",
  contactUs: "/contact-us",
  faqs: "/faqs",
  learn: "/learn",
  myAccount: "/my-account",
  recipes: "/recipes",
  search: "/search",
  notFound: "/404",
  pantrySearch: "/pantry-search",
  people: "/people",
  mealPlanner: "/meal-planner",
  print: "/print",
  salad: "/salad",
};

export const standardNutrition = [
  { id: "energy", title: "Energy", metric: "kJ", dailyValue: 8700 },
  { id: "protein", title: "Protein", metric: "g", dailyValue: 50 },
  { id: "fat", title: "Total Fat", metric: "g", dailyValue: 70 },
  { id: "saturatedFat", title: "Saturated Fat", metric: "g", dailyValue: 24 },
  {
    id: "carbohydrateAvailable",
    title: "Carbohydrate",
    metric: "g",
    dailyValue: 310,
    abbreviated: "Carbs",
  },
  { id: "dietaryFibre", title: "Dietary Fibre", metric: "g" },
  { id: "sodium", title: "Sodium", metric: "mg" },
  { id: "iron", title: "Iron", metric: "mg" },
  { id: "zink", title: "Zinc", metric: "mg" },
  { id: "vitaminBTwelve", title: "Vitamin B12", metric: "µg" },
];

export const allNutrition = [
  { id: "servingSize", title: "Weight", metric: "g" },
  { id: "energy", title: "Energy", metric: "kJ" },
  { id: "protein", title: "Protein", metric: "g" },
  { id: "fat", title: "Total Fat", metric: "g" },
  { id: "saturatedFat", title: "Saturated Fat", metric: "g" },
  { id: "pufa", title: "Polyunsaturated fat", metric: "g" },
  { id: "mufa", title: "Monounsaturated fat", metric: "g" },
  { id: "cholesterol", title: "Cholesterol", metric: "mg" },
  {
    id: "carbohydrateAvailable",
    title: "Carbohydrates",
    metric: "g",
  },
  { id: "sugars", title: "Sugars", metric: "g" },
  { id: "dietaryFibre", title: "Dietary Fibre", metric: "g" },
  { id: "thiamin", title: "Thiamin (Vitamin B1)", metric: "µg" },
  { id: "riboflavin", title: "Riboflavin (Vitamin B2)", metric: "mg" },
  { id: "niacin", title: "Niacin (Vitamin B3)", metric: "mg" },
  { id: "vitaminC", title: "Vitamin C", metric: "mg" },
  { id: "vitaminD", title: "Vitamin D", metric: "µg" },
  { id: "vitaminE", title: "Vitamin E", metric: "mg" },
  { id: "vitaminBSix", title: "Vitamin B6", metric: "mg" },
  { id: "vitaminBTwelve", title: "Vitamin B12", metric: "µg" },
  { id: "dietryFolate", title: "Dietary Folate", metric: "µg" },
  { id: "vitaminA", title: "Vitamin A", metric: "µg" },
  { id: "sodium", title: "Sodium", metric: "mg" },
  { id: "potassium", title: "Potassium", metric: "mg" },
  { id: "magnesium", title: "Magnesium", metric: "mg" },
  { id: "calcium", title: "Calcium", metric: "mg" },
  { id: "iron", title: "Iron", metric: "mg" },
  { id: "zink", title: "Zinc", metric: "mg" },
  { id: "selenium", title: "Selenium", metric: "µg" },
  { id: "sugars", title: "Sugars", metric: "g", dailyValue: 90 },
  { id: "sodium", title: "Sodium", metric: "mg", dailyValue: 2300 },
  { id: "iron", title: "Iron*", metric: "mg", dailyValue: 13 },
];

export const getFormLink =
  "https://getform.io/f/33bee31e-7418-4239-9bb0-87ede6ac16c0";

export const newsletterScript = `(function () {
  var script = document.getElementById('0.190847483989554');
  var form = script.parentNode;
  var inputs = form.getElementsByTagName("INPUT");
  var submit_count = 0;

  var enable_double_submit = false;

  for (var i = 0; i < inputs.length; i++) {
    var my_input = inputs[i];
    if (my_input.type === "submit") {
      var container = my_input.parentNode;
      if (container.className.match(/enable-double-submit/)) {
        enable_double_submit = true;
      }
    }
  }

  form.addEventListener("submit", function (evt) {
    if (submit_count >= 1 && !enable_double_submit) {
      evt.preventDefault();
    }
    submit_count++;
  });
})();`;

export const newsletterSubscribeUrl =
  "https://5022e087.sibforms.com/serve/MUIEABN8ZgAqFwpdcESoFKwwJt32AOmyl0nat3MRgh8GXyTXDDTft9ElrLc3C-Yk56a-DjIySr0W_APegj5oY_3QYq03GOfDVOSLveOQScb3PCH2iHbU982lakX_0-VjwI7gFU24kmGZ6uly-egZ5WniKduuRAWFiaFMkPGriUU47Z5h2HgNX2lbjSInz1UXyUqS7Z4tRuLq0H4k";

export const defaultFirebaseContextValue = {
  app: null,
  analytics: null,
  auth: null,
  database: null,
  messaging: null,
};
