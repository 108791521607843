import { combineReducers } from "redux";
import core from "./core/reducers";
import user from "./user/reducers";
import planner from "./planner/reducers";

export default combineReducers({
  core,
  user,
  planner,
});
