import { all, takeLatest } from "redux-saga/effects";

import coreActions from "@state/core/actions";
import updateSearchEntry from "./updateSearchEntry";

export default function* sagaWatcher() {
  yield all([
    takeLatest(coreActions.updateSearchEntry.type, updateSearchEntry),
  ]);
}
