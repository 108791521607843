exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-competition-tsx": () => import("./../../../src/pages/competition.tsx" /* webpackChunkName: "component---src-pages-competition-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-meal-planner-tsx": () => import("./../../../src/pages/meal-planner.tsx" /* webpackChunkName: "component---src-pages-meal-planner-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-pantry-search-tsx": () => import("./../../../src/pages/pantry-search.tsx" /* webpackChunkName: "component---src-pages-pantry-search-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */),
  "component---src-pages-print-tsx": () => import("./../../../src/pages/print.tsx" /* webpackChunkName: "component---src-pages-print-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-pages-salad-result-tsx": () => import("./../../../src/pages/salad-result.tsx" /* webpackChunkName: "component---src-pages-salad-result-tsx" */),
  "component---src-pages-salad-tsx": () => import("./../../../src/pages/salad.tsx" /* webpackChunkName: "component---src-pages-salad-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-category-landing-tsx": () => import("./../../../src/templates/categoryLanding.tsx" /* webpackChunkName: "component---src-templates-category-landing-tsx" */),
  "component---src-templates-landing-template-tsx": () => import("./../../../src/templates/landingTemplate.tsx" /* webpackChunkName: "component---src-templates-landing-template-tsx" */),
  "component---src-templates-people-inner-template-tsx": () => import("./../../../src/templates/peopleInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-people-inner-template-tsx" */),
  "component---src-templates-recipe-inner-template-tsx": () => import("./../../../src/templates/recipeInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-recipe-inner-template-tsx" */),
  "component---src-templates-static-page-template-tsx": () => import("./../../../src/templates/staticPageTemplate.tsx" /* webpackChunkName: "component---src-templates-static-page-template-tsx" */)
}

