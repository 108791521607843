import { createActions } from "re-reduced";
import { QuickViewRecipe, SearchFacets } from "./types";

export default createActions("CORE", create => ({
  clearState: create.action(),
  updateSearchEntry: create.asyncAction<string, string>(),
  updateWindowWidth: create.action<number>(),
  setSelectedCategory: create.action<string>(),
  toggleSearchOverlayVisible: create.action<boolean>(),
  toggleAuthDrawerVisible: create.action<boolean>(),
  updateCurrentPagination: create.action<number>(),
  clearSearchParams: create.action(),
  clearSearchEntry: create.action(),
  updateSearchFacets: create.action<SearchFacets | undefined>(),
  updatePantrySearchFacets: create.action<string[]>(),
  setQuickViewRecipe: create.action<QuickViewRecipe | undefined>(),
  updateWhiskLoaded: create.action<boolean>(),
}));
