import { createActions } from "re-reduced";

import { SanityRecipes } from "@graphql-types";
import { Session, UserPreference } from "./types";

export default createActions("USER", create => ({
  setUserPreferences: create.action<UserPreference>(),
  setUserSession: create.action<Session>(),
  setUserName: create.action<string>(),
  clearSession: create.action(),
  setSavedRecipes: create.action<string[]>(),
  setPrintRecipe: create.action<SanityRecipes | undefined>(),
  setSelectedIngredients: create.action<any>(),
  setUserDataRecipes: create.action<any>(),
}));
