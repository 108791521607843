import { persistReducer } from "redux-persist";
import { createReducer, reduce } from "re-reduced";
import storage from "redux-persist/lib/storage";

import { PlannerState } from "./types";
import actions from "./actions";

const INITIAL_STATE: PlannerState = {
  selectedRecipes: [],
  savedPlannerRecipes: [],
};

export const persistConfig = {
  key: "planner",
  storage: storage,
  version: 1,
};

const reducer = createReducer<PlannerState>(
  [
    reduce(actions.updateSelectedRecipes, (state, payload) => ({
      ...state,
      selectedRecipes: payload,
    })),
    reduce(actions.clearSelectedRecipes, state => ({
      ...state,
      selectedRecipes: [],
    })),
    reduce(actions.updateSavedPlannerRecipes, (state, payload) => ({
      ...state,
      savedPlannerRecipes: payload,
    })),
    reduce(actions.clearSavedPlannerRecipes, state => ({
      ...state,
      savedPlannerRecipes: [],
    })),
  ],
  INITIAL_STATE
);

export default persistReducer(persistConfig, reducer);
