import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";
import { Action } from "re-reduced";

import actions from "@state/core/actions";

export default function* updateSearchEntry(
  action: Action<string>
): SagaIterator {
  yield put(actions.updateSearchEntry.request());
  try {
    yield put(actions.updateSearchEntry.success(action.payload));
  } catch (error) {
    yield put(actions.updateSearchEntry.failure(error));
  }
}
