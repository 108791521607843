import { persistReducer } from "redux-persist";
import { createReducer, reduce } from "re-reduced";
import storage from "redux-persist/lib/storage";

import { UserState } from "./types";
import actions from "./actions";

const INITIAL_STATE: UserState = {
  session: undefined,
  savedRecipes: [],
  cookedRecipes: [],
  userDataRecipes: [],
};

export const persistConfig = {
  key: "user",
  storage: storage,
  version: 1,
};

const reducer = createReducer<UserState>(
  [
    reduce(actions.setUserSession, (state, payload) => ({
      ...state,
      ...state.savedRecipes,
      session: { ...payload },
    })),
    reduce(actions.setUserName, (state, payload) => ({
      ...state,
      session: { ...state.session, name: payload },
    })),
    reduce(actions.clearSession, _state => INITIAL_STATE),
    reduce(actions.setSavedRecipes, (state, payload) => ({
      ...state,
      savedRecipes: payload,
    })),
    reduce(actions.setPrintRecipe, (state, payload) => ({
      ...state,
      printRecipe: payload,
    })),
    reduce(actions.setSelectedIngredients, (state, payload) => ({
      ...state,
      selectedIngredients: payload,
    })),
    reduce(actions.setUserDataRecipes, (state, payload) => ({
      ...state,
      userDataRecipes: payload,
    })),
    reduce(actions.setUserPreferences, (state, payload) => ({
      ...state,
      userPreferences: payload,
    })),
  ],
  INITIAL_STATE
);

export default persistReducer(persistConfig, reducer);
