import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getAuth,
  Auth,
  signOut as FBSignOut,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  Firestore,
  doc,
  setDoc,
  getFirestore,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { isBrowser } from "./helper";
import { RecipeFormData } from "./types";
import { toast } from "react-toastify";
let app: FirebaseApp;
let auth: Auth;
let database: Firestore;

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
};

const initFirebase = () => {
  if (!isBrowser()) return null;

  app = app || initializeApp(firebaseConfig);
  auth = getAuth(app);
  database = getFirestore(app);

  return { app, auth, database };
};

const useFirebaseGetDoc = async (collection: string, docId: string) => {
  if (!database || !collection || !docId) return null;

  try {
    const docRef = doc(database, collection, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.log("Error on update firebase value: ", error);

    return false;
  }
};

const useFirebaseSetDoc = async (
  collection: string,
  docId: string | undefined,
  data: any
) => {
  if (!database || !collection || !docId) return false;

  try {
    await setDoc(doc(database, collection, docId), data, { merge: true });
    return true;
  } catch (error) {
    console.log("Error on update firebase value: ", error);

    return false;
  }
};

const useAddCommentToRecipe = async (
  savedRecipes: any,
  userUId: string,
  recipeID: string,
  formData: RecipeFormData,
  setUserRecipeData?: (savedRecipes: any) => void
) => {
  const updatedRecipeData = () => {
    const recipe = {
      ...formData,
      recipeID,
    };
    if (savedRecipes == undefined) {
      return [recipe];
    }

    //fitler existing recipes
    const filter = savedRecipes.filter(
      (recipe: any) => recipeID != recipe.recipeID
    );
    console.log({ filter });

    return [...filter, recipe];
  };

  const updatedRecipes = updatedRecipeData();

  try {
    await updateDoc(doc(database, "users", userUId), {
      userDataRecipes: updatedRecipes,
    });
    toast.success("Successfully save recipe", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    return "success";
  } catch (error) {
    setUserRecipeData && setUserRecipeData(updatedRecipes);

    return "error";
  }
};

const useUpdateUserDetails = async (uid: string, formData: any) => {
  console.log({ formData, uid });
  if (uid == null || formData == null) return null;

  const returnGender = () => {
    if (formData.gender == "other") {
      return formData.genderOther;
    }

    return formData.gender ?? null;
  };

  Object.keys(formData).map(key => {
    console.log({ key, value: formData[key] });
    const value = formData[key];
    if (Array.isArray(value)) {
      return (formData[key] = value.toString());
    }
  });

  try {
    await updateDoc(doc(database, "users", uid), {
      ...formData,
      PREFERENCES_COMPLETED: true,
      gender: returnGender(),
    });
    toast.success("Successfully save recipe", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    return "success";
  } catch (error) {
    console.log("firebase result", error);
    return "error";
  }
};

const useSignInWithEmail = async (email: string, password: string) => {
  if (!auth || !email || !password) return null;

  try {
    const result = await signInWithEmailAndPassword(auth, email, password);

    return result;
  } catch (error) {
    console.log("firebase result", error);
    toast.error("Invalid email or password");
    return error;
  }
};

const useSignupWithEmail = async (email: string, password: string) => {
  if (!auth || !email || !password) return null;

  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    console.log({ result });

    return result;
  } catch (error) {
    console.log("firebase result", error);
    return error;
  }
};

const useFirebaseGoogleSignIn = async () => {
  const googleProvider = new GoogleAuthProvider();
  const signin = await signInWithPopup(auth, googleProvider);
  if (signin) {
    return signin;
  }
  return null;
};

const useFirebaseFacebookSignIn = async () => {
  const facebookProvider = new GoogleAuthProvider();
  const signin = await signInWithPopup(auth, facebookProvider);
  if (signin) {
    return signin;
  }
  return null;
};

const useFirebaseForgotPassword = async (email: string) => {
  if (!auth || !email) return null;

  try {
    const result = await sendPasswordResetEmail(auth, email);
    toast.success(`Please check your inbox `, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    return result;
  } catch (error) {
    console.log("firebase result", error);
    toast.error(error, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    return error;
  }
};

export {
  initFirebase,
  useFirebaseSetDoc,
  useFirebaseGetDoc,
  useAddCommentToRecipe,
  useUpdateUserDetails,
  useSignInWithEmail,
  useFirebaseGoogleSignIn,
  useFirebaseFacebookSignIn,
  useSignupWithEmail,
  useFirebaseForgotPassword,
  // signIn,
  // signOut,
  // updateFirestoreValue
};
