import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "@components/styles/toastStyles.css";
import "@components/styles/globalStyles.css";
import wrapWithProvider from "./wrap-with-provider";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
}

export const wrapPageElement = wrapWithProvider;
