import { persistReducer } from "redux-persist";
import { createReducer, reduce } from "re-reduced";
import storage from "redux-persist/lib/storage";

import { CoreState, ingredientTitleKey, RequestStatus } from "./types";
import actions from "./actions";

const INITIAL_STATE: CoreState = {
  searchEntry: undefined,
  searchStatus: RequestStatus.Idle,
  windowWidth: 0,
  searchOverlayVisible: false,
  authDrawerVisible: false,
  currentPagination: 1,
};

export const persistConfig = {
  key: "core",
  storage: storage,
  version: 2,
  blacklist: ["searchEntry"],
};

const reducer = createReducer<CoreState>(
  [
    reduce(actions.clearState, _state => INITIAL_STATE),
    reduce(actions.updateSearchEntry.request, state => ({
      ...state,
      searchStatus: RequestStatus.Pending,
    })),
    reduce(actions.updateSearchEntry.failure, state => ({
      ...state,
      searchStatus: RequestStatus.Failed,
    })),
    reduce(actions.updateSearchEntry.success, (state, payload) => ({
      ...state,
      searchEntry: payload,
      searchStatus: RequestStatus.Success,
    })),
    reduce(actions.updateWindowWidth, (state, payload) => ({
      ...state,
      windowWidth: payload,
    })),
    reduce(actions.setSelectedCategory, (state, payload) => ({
      ...state,
      selectedCategory: payload,
    })),
    reduce(actions.toggleSearchOverlayVisible, (state, payload) => ({
      ...state,
      searchOverlayVisible: payload,
    })),
    reduce(actions.toggleAuthDrawerVisible, (state, payload) => ({
      ...state,
      authDrawerVisible: payload,
    })),
    reduce(actions.updateCurrentPagination, (state, payload) => ({
      ...state,
      currentPagination: payload,
    })),
    reduce(actions.clearSearchParams, state => ({
      ...state,
      searchEntry: undefined,
      selectedCategory: undefined,
      currentPagination: 1,
      searchFacets: undefined,
    })),
    reduce(actions.clearSearchEntry, state => ({
      ...state,
      searchEntry: undefined,
    })),
    reduce(actions.updateSearchFacets, (state, payload) => ({
      ...state,
      searchFacets: payload,
    })),
    reduce(actions.updatePantrySearchFacets, (state, payload) => ({
      ...state,
      pantrySearchFacets: {
        [ingredientTitleKey]: payload,
      },
    })),
    reduce(actions.setQuickViewRecipe, (state, payload) => ({
      ...state,
      quickViewRecipe: payload,
    })),
    reduce(actions.updateWhiskLoaded, (state, payload) => ({
      ...state,
      whiskLoaded: payload,
    })),
  ],
  INITIAL_STATE
);

export default persistReducer(persistConfig, reducer);
