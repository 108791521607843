import store, { persistor } from "./src/state/createStore";
import { Provider } from "react-redux";
import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import FirebaseProvider from "./src/util/firebaseProvider";

import "firebase/auth";
import "firebase/firestore";

export default ({ element }: any) => {
  if (typeof window === "undefined") {
    return <Provider store={store}>{element}</Provider>;
  }
  return (
    <FirebaseProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {element}
        </PersistGate>
      </Provider>
    </FirebaseProvider>
  );
};
