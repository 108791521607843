import { Maybe, SanityIngredientGroup } from "@graphql-types";

export const ingredientTitleKey =
  "ingredients.ingredients.ingredient.displayName";
export interface CoreState {
  searchEntry?: string;
  searchStatus: RequestStatus;
  searchFacets?: SearchFacets;
  pantrySearchFacets?: PantrySearchFacets;
  currentPagination: number;
  windowWidth: number;
  selectedCategory?: string;
  searchOverlayVisible: boolean;
  authDrawerVisible: boolean;
  quickViewRecipe?: QuickViewRecipe;
}

export interface SearchFacets {
  healthTags?: string[];
  cusineTags?: string[];
}

export interface PantrySearchFacets {
  [ingredientTitleKey]: string[];
}

export interface QuickViewRecipe {
  id?: string | null;
  title: string;
  prepTime?: string | null;
  cookTime?: string | null;
  chefName?: string | null;
  ingredients?: Maybe<SanityIngredientGroup>[] | null | undefined;
  slug?: string | null;
  servingSize?: string | null;
}

export enum RequestStatus {
  Idle = "Idle",
  Pending = "Pending",
  Success = "Success",
  Failed = "Failed",
}

export interface SaladIngredients {}
