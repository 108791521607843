import React, { ReactNode, useEffect, useState } from "react";
import FirebaseContext from "../state/firebaseContext";
import { initFirebase } from "@util/firebase";
import { defaultFirebaseContextValue } from "@util/constants";
import { FirebaseInstance } from "./types";

interface Props {
  children: ReactNode;
}

const FirebaseProvider = ({ children }: Props) => {
  const [firebase, setFirebase] = useState<FirebaseInstance>(
    defaultFirebaseContextValue
  );

  useEffect(() => {
    const firebaseInstance = initFirebase();

    if (firebaseInstance) {
      setFirebase(firebaseInstance);
    }
  }, []);

  if (!firebase) return children;
  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
