import {
  compose,
  legacy_createStore as createStore,
  applyMiddleware,
} from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";
import sagas from "./sagas";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();

const enhancers = compose(
  applyMiddleware(sagaMiddleware),
  typeof window !== "undefined" &&
    //@ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    process.env.GATSBY_NODE_ENV !== "production"
    ? //@ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f: any) => f
);
const store = createStore(persistedReducer, enhancers);

sagaMiddleware.run(sagas);

export const persistor = persistStore(store);
export default store;
